<template>
  <div>
    <b-card v-if="paymentRequired">
      <a
        class="btn btn-primary float-right"
        :href="`/betaling/${reservation.ReservationOrderID}`"
        >Betaal nu
        <font-awesome-icon class="ml-1" fas :icon="['fas', 'credit-card']"
      /></a>

      <p v-if="!reservation.OrderID" class="pt-2">
        Deze reservatie kun je binnen de 14 dagen wijzigen.<br />
        Toch zeker van je zaak? Betaal dan nu.
      </p>
      <p v-if="reservation.OrderID" class="pt-2">
        Je reservatie is definitief. Betaal nu vast online, de afhaler is je
        dankbaar
      </p>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    reservation: {
      type: Object,
      required: true
    },
    paymentRequired: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style></style>
