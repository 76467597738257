<template>
  <b-modal
    :id="modalid"
    v-model="modalShow"
    :hide-header="true"
    :hide-footer="true"
  >
    <h5>
      <span class="text-primary">
        <font-awesome-icon icon="exclamation-circle" />
      </span>
      Opgelet!
    </h5>
    <p class="my-4">
      {{ message }}
    </p>
    <b-button variant="primary" @click="confirm()">Bevestig</b-button>
    <b-button variant="link" @click="hide()">Annuleren</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    show: {
      required: false,
      type: Boolean,
      default: function() {
        return false
      }
    },
    modalid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    }
  },
  data() {
    return {
      modalShow: false
    }
  },
  watch: {
    show: function(val) {
      this.modalShow = val
      if (val === false) {
        this.$emit('hide')
      }
    }
  },
  created() {
    this.modalShow = this.show
  },
  methods: {
    hide: function() {
      this.modalShow = false
      this.$emit('hide')
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>

<style scss>
.modal-content {
  border-radius: 5px;
}
</style>
