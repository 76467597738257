<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`Reservering/bestelling ${reservationID}`"
    />
    <h1>
      Details van de reservering/bestelling #{{
        reservation.ReservationOrderID
      }}
    </h1>
    <b-card v-if="reservation.WarehouseID">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Status</strong>
            <br />
            {{ reservationStatus }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Afhaallocatie</strong>
            <br />
            {{ reservation.WarehouseID }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Afhaaldatum</strong>
            <br />
            {{ reservation.DateTimeExpectedStart | moment('DD/MM/YYYY') }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Terugbrengdatum</strong>
            <br />
            {{ reservation.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Huurperiodes</strong>
            <br />
            {{ reservation.CustomFields.CUST_WeekPeriod }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>Betaling</strong>
            <br />
            {{ paymentStatus }}
          </p>
        </b-col>
        <b-col sm="12">
          <div><strong>Activiteit omschrijving:</strong></div>
          <div v-html="reservation.CustomFields.CUST_ActivityDescription"></div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col sm="12">
        <b-table
          v-if="reservationItems.length > 0"
          :fields="fields"
          :items="reservationItems"
          class="mt-4"
        >
          <template v-slot:cell(ItemDescription)="data">
            <small
              v-if="data.item.ItemID && data.item.Composition"
              class="pl-3"
              >{{ data.item.ItemDescription }}</small
            >
            <span v-else>{{ data.item.ItemDescription }}</span>
          </template>
          <template v-slot:cell(Price)="data">
            <div v-if="data.item.Price > 0" class="form-group text-right">
              &euro;
              {{
                (
                  Math.round((data.item.TotalIncVAT / data.item.Amount) * 100) /
                  100
                )
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
          <template v-slot:cell(ReservationItemID)="data">
            <span
              v-if="data.item.ReservationStatus !== 'Cancelled'"
              class="text-primary"
              style="cursor:pointer"
              variant="link"
              @click="
                cancelReservationItemPrompt({
                  reservationOrderID: reservation.ReservationOrderID,
                  reservationItemID: data.item.ReservationItemID,
                  adjustable: data.item.Adjustable,
                  ItemDescription: data.item.ItemDescription
                })
              "
              ><span
                v-if="!data.item.ItemID && data.item.Composition && allowCancel"
                >Annuleer</span
              >
              <span
                v-if="data.item.ItemID && !data.item.Composition && allowCancel"
                >Annuleer</span
              ></span
            >
            <p v-else class="text-primary">Geannuleerd</p>
          </template>
          <template v-slot:cell(TotalExcVAT)="data">
            <div v-if="data.item.Price > 0" class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <div class="prices">
          <b-row>
            <b-col
              xs="6"
              sm="6"
              md="10"
              lg="10"
              class="text-right cart-total-price"
            >
              <p>
                <strong>BTW:</strong>
              </p>
            </b-col>
            <b-col
              v-if="reservation.TotalVAT"
              xs="6"
              sm="6"
              md="2"
              lg="2"
              class="text-right"
            >
              <p>
                &euro;
                {{
                  reservation.TotalVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
            </b-col>
            <b-col
              xs="6"
              sm="6"
              md="10"
              lg="10"
              class="text-right cart-total-price"
            >
              <p>
                <strong>Totaalbedrag:</strong>
              </p>
            </b-col>
            <b-col
              v-if="reservation.TotalIncVAT"
              xs="6"
              sm="6"
              md="2"
              lg="2"
              class="text-right"
            >
              <p>
                &euro;
                {{
                  reservation.TotalIncVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col
        v-if="reservation.CustomFields.CUST_ReportID"
        cols="12"
        class="text-right"
      >
        <hr />

        <b-button variant="primary" class="ml-3 mb-2" @click="getReport()">
          Download {{ reservation.ReportFileName }}
        </b-button>

        <b-button
          v-if="!reservation.OrderID && allowCancel"
          variant="primary"
          class="float-right float-xs-center ml-3"
          @click="show = true"
        >
          Bestelling annuleren
          <font-awesome-icon
            v-if="addReservationToCartPending"
            class="fa-spin"
            far
            :icon="['fad', 'spinner']"
          />
        </b-button>
      </b-col>

      <b-col cols="12" class="mt-5">
        <reservation-info
          :reservation="reservation"
          :payment-required="paymentStatus === 'Nog niet betaald'"
        />
      </b-col>
    </b-row>
    <modal
      modalid="prompt"
      :show="show"
      :message="promptMessage"
      @hide="show = false"
      @confirm="cancelReservation()"
    />
  </b-container>
</template>

<script>
import {
  addReservationToCart,
  adjustReservation,
  cancelReservation,
  cancelReservationItem,
  getReservationItem,
  getReservationItems,
  getReservationReport
} from '@/services/ReservationService'
import Modal from '@/components/global/modal-prompt.vue'
import ReservationInfo from '@/components/Reservation/ReservationInfo.vue'

export default {
  components: {
    modal: Modal,
    ReservationInfo
  },
  data() {
    return {
      reservationItems: {},
      reservation: {},
      addReservationToCartPending: false,
      show: false,
      sortBy: 'CompositionDescription',
      fields: [
        {
          key: 'ReservationItemID',
          label: 'Annuleren'
        },
        {
          key: 'CompositionDescription',
          class: 'd-none'
        },
        {
          key: 'ItemID',
          class: 'd-none'
        },
        {
          key: 'ItemDescription',
          label: 'Artikel'
        },

        {
          key: 'Price',
          label: 'Prijs',
          class: 'text-right'
        },
        {
          key: 'Amount',
          label: 'Aantal',
          class: 'text-center'
        },

        {
          key: 'TotalExcVAT',
          label: 'Totaal',
          class: 'text-right'
        }
      ],
      breadCrumbs: [
        { page: 'index', title: 'Uitleendienst', path: '/' },
        { page: 'account', title: 'Mijn account', path: '/account' }
      ]
    }
  },
  computed: {
    reservationID: function() {
      return this.$route.params.reservationID
    },
    paymentStatus: function() {
      if (this.reservation.CustomFields.CUST_IsPaid) return 'Betaald'
      if (this.reservation.CustomFields.CUST_OnInvoice) return 'Op factuur'
      return 'Nog niet betaald'
    },
    allowCancel() {
      const status = this.reservation.ReservationStatus
      if (this.reservation.OrderID) return false

      if (
        status === 'Cancelled' ||
        status === 'Expired' ||
        status === 'Order' ||
        status === 'Picked' ||
        status === 'Rejected'
      ) {
        return false
      }

      if (this.$store.state.account.RoleName !== 'portal') return true

      if (this.reservation.ContactID === this.$store.state.contactID)
        return true

      return false
    },
    promptMessage: function() {
      return `Weet je zeker dat je bestelling #${this.reservationID} wilt annuleren?`
    },
    reservationStatus: function() {
      let status = this.reservation.ReservationStatus

      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Reservering bevestigd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return 'Aangevraagd'
    }
  },
  mounted: async function() {
    await this.fetchReservation()

    this.reservationItems = await getReservationItems({
      reservationID: this.reservationID
    })
  },
  methods: {
    cancelReservationItemPrompt: async function({
      reservationOrderID,
      reservationItemID,
      adjustable,
      ItemDescription
    }) {
      if (adjustable === 1) {
        let cancel = confirm(
          `Alle artikelen uit de samenstelling "${ItemDescription}" worden geannuleerd, weet u zeker dat u deze artikelen wilt annuleren?`
        )
        if (cancel === false) return
      }

      if (!adjustable) {
        let cancel = confirm(
          `Artikel "${ItemDescription}" word geannuleerd, weet u zeker dat u deze artikelen wilt annuleren?`
        )
        if (cancel === false) return
      }

      await cancelReservationItem({ reservationOrderID, reservationItemID })
      this.refreshReservationItems()
      await this.fetchReservation()
    },
    getReport: async function() {
      await getReservationReport({
        reservationOrderID: this.reservation.ReservationOrderID,
        reportID: this.reservation.CustomFields.CUST_ReportID,
        attachmentToken: this.reservation.AttachmentToken,
        fileName: `${this.reservation.ReservationOrderID}-${this.reservation.CustomFields.CUST_ReportFileName}`
      })
    },
    addReservationToCart: async function() {
      this.addReservationToCartPending = true
      const result = await addReservationToCart({
        reservationID: this.reservationID
      })

      if (result.data === 'OK') {
        this.$router.push({
          name: 'cart',
          query: { addedReservationItems: true }
        })
      }
      this.addReservationToCartPending = false
    },
    refreshReservationItems: async function() {
      this.reservationItems = await getReservationItems({
        reservationID: this.reservationID
      })
    },
    cancelReservation: async function() {
      await cancelReservation({ reservationID: this.reservationID })
      this.$router.push({
        name: 'account',
        query: {
          infoMessage: `${
            this.reservation.ReservationStatus === 'Active'
              ? 'Aanvraag'
              : 'Bestelling'
          } #${this.reservationID} werd succesvol geannuleerd`
        }
      })
    },
    async fetchReservation() {
      this.reservation = await getReservationItem({
        reservationID: this.reservationID
      })
    },
    async redoReservation() {
      this.$toasted.show(
        'De reservering wordt opnieuw in je winkelwagen geplaatst, een moment geduld alstublieft.'
      )
      const result = await adjustReservation({
        reservationOrderID: this.reservation.ReservationID
      })

      await this.fetchReservation()

      if (result !== 'ERROR') {
        this.$toasted.show(
          'De reservering is geannuleerd en de artikelen zijn in uw winkelwagen geplaatst'
        )
        this.$emit('reservationCancelled')
      } else {
        this.$toasted.show('Er is iets mis gegaan, probeer het later opnieuw')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-h-100 .card {
  height: 100% !important;
  .card-body {
    padding-bottom: 100px;
  }
  .card-body-bottom {
    position: absolute;
    bottom: 20px;
  }
}
.prices {
  padding-right: 10px;
}
</style>
